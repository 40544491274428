import Vue from 'vue'
import VueRouter from 'vue-router'

// Modules
import home from './modules/home'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'App', redirect: '/home' },

  ...home,
]

const router = new VueRouter({
  mode: 'abstract',
  routes,
})

export default router
