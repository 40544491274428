// =========================== //
// ===== Core components ===== //
// =========================== //

import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'


// =============================== //
// ===== Create global event ===== //
// =============================== //

/// This is deprecated in Vue 3 and only works in Vue 2
/// @link https://www.educative.io/answers/what-is-the-event-bus-in-vuejs
/// @link https://v3-migration.vuejs.org/breaking-changes/events-api.html#event-bus

export const eventBus = new Vue()


// ============================== //
// ===== Import stylesheets ===== //
// ============================== //

// import './styles/nightvue.scss'
// import './styles/style.scss'
// import './styles/animations/_animations.scss'


// ========================================== //
// ===== Import and register components ===== //
// ========================================== //


// ================================================= //
// ===== Import and register 3rd party plugins ===== //
// ================================================= //

import Waves from 'vue-waves-effect'
import 'vue-waves-effect/dist/vueWavesEffect.css'
import Vue2TouchEvents from 'vue2-touch-events'
import vueCustomElement from 'vue-custom-element'
import '@ungap/custom-elements'

Vue.use(Waves)
Vue.use(Vue2TouchEvents)
Vue.use(vueCustomElement)


// ======================================= //
// ===== Construct widget parent DIV ===== //
// ======================================= //
document.write('<lycaste-widget></lycaste-widget>')


// ==================== //
// ===== Init app ===== //
// ==================== //
App.store = store
App.router = router
Vue.customElement('lycaste-widget', App)
