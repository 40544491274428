<template>
  <div
    class="widget position-fixed left-1 m-0 left-md-3 bottom-1 bottom-md-3 no-select cursor-pointer speed-base timing-emphasized"
    :class='[{"widget--initiated" : widgetInitiated},{"widget--expanded" : bodyAutoExpanded},{"widget--hovered" : widgetHovered}]'
    :style='widgetStyles'
    @mouseenter='hoverWidget()'
    @mouseleave='unhoverWidget()'
    @click='resolveWidgetClick()'>
    <div class="widget__square position-absolute radius-large z-plus-3 left-0 top-0 cursor-pointer">
      <video
        class="widget__video position-fixed top-0 right-0 bottom-0 left-0 z-minus-1 radius-large"
        autoplay
        loop
        muted
        :poster="posterUrl">
        <source
          :src="videoUrl"
          type="video/mp4">
      </video>
    </div>
    <div class="widget__body d-flex align-items-center position-absolute radius-large w-100 left-0 top-0 bottom-0 m-auto z-plus-2 shadow-type-1 bg-white cursor-pointer ">
      <div
        :class='{"widget__content--autoexpanded" :  widgetHovered}'
        v-if='widgetHovered || bodyAutoExpanded'
        class="widget__content d-flex align-items-center w-100 h-100 px-75 cursor-pointer">
        <div class="d-flex flex-column justify-content-center pr-1">
          <div class="widget__title font-weight-700 clr-primary-dark-5 line-height-25 mb-25">Know Your Skin Better</div>
          <div class="widget__desc small clr-gray-4">Take the <span class="font-weight-500">AI</span> Skin Test</div>
        </div>
        <div class="widget__icon icon-size-xl ml-auto" />
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../main.js"

export default {
  name: 'Home',
  data() {
    return {
      widgetInitiated: false,
      widgetHovered: false,
      bodyAutoExpanded: false,
      widgetExpandValue: 320,
      expandOn: ['md', 'lg', 'xl'],
      widgetStyles: {
        width: ""
      }
    }
  },
  computed: {
    currentBreakpoint() {
      /// Returns current 'morphological' breakpoint value based on window width
      ///
      /// @example
      ///   'xs' | 'sm' | 'md' | 'lg' | 'xl'

      return this.$store.state.config.currentBreakpoint
    },

    videoUrl() {
      return process.env.VUE_APP_VIDEO_URL
    },

    posterUrl() {
      return process.env.VUE_APP_POSTER_URL
    },
  },
  mounted() {
    eventBus.$on('initWidget', () => { this.initWidget() })
  },
  methods: {
    initWidget() {
      this.widgetInitiated = true
    },

    autoExpandWidget() {
      let self = this

      setTimeout(() => {
        self.bodyAutoExpanded = true
      }, 9000)
    },

    manualExpandWidget() {
      this.widgetHovered = true
      this.widgetStyles.width = this.widgetExpandValue + "px"
    },

    minimizeWidget() {
      this.widgetHovered = false
      this.widgetStyles.width = ""
    },

    resolveWidgetClick() {
      window.location.assign(process.env.VUE_APP_RELOCATION_DESTINATION)
    },

    hoverWidget() {
      if (this.expandOn.some(el => el == this.currentBreakpoint)) {
        this.manualExpandWidget()
      }
    },

    unhoverWidget() {
      if (!this.bodyAutoExpanded) {
        this.minimizeWidget()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/nightvue.scss';
@import '@/styles/animations/_animations.scss';

.widget {
  font-family: map-get($font-families, base);
  will-change: width;
  width: $hiddenDimension;
  height: $hiddenDimension;
  opacity: opacity(0);
  z-index: 999997 !important;
  transform: scale(0.8) translate3d(20px, 20px, 0);

  // States
  &--initiated {
    @include animation(
      $name: lycaste-init,
      $duration: slow,
      $timing: emphasized,
    );
  }

  &--expanded {
    width: $openedDimension;
  }

  // Components
  &__square {
    @include icon-size($hiddenDimension);
  }

  &__video {
    @include icon-size($hiddenDimension);

    object-fit: cover;
  }

  &__body {
    height: $hiddenDimension - 8px;
    padding-left: $hiddenDimension;
  }

  &__content {
    opacity: opacity(0);

    &--autoexpanded {
      @include animation(
        $name: lycaste-fade-in,
        $duration: slow,
        $delay: .3s,
        $timing: emphasized,
      );
    }
  }

  &__icon {
    background-image: url('../assets/icons/caret.svg');
    background-size: 24px 24px;
  }
}
</style>
